<template>
  <nav class="bg-white flex justify-between items-center p-6 shadow">
    <a href="/umwelt/demos/catalog">
      <img
        :src="require('@/assets/images/umwelt-logo.png')"
        class="h-16"
        alt="Umwelt Logo"
      />
    </a>
    <div>
      <img
        :src="require('@/assets/images/logo.svg')"
        class="h-8"
        alt="Texperia Logo"
      />
    </div>
  </nav>
</template>

<script>
export default {
  name: "DesktopNavbar",
  components: {},
  data() {
    return {
      sidebar: false,
      hrUrl: process.env.VUE_APP_HR_URL,
    };
  },
  methods: {},
};
</script>

<style scoped></style>
