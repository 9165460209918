<template>
  <div class="sample-catalog relative w-full h-full text-black">
    <Navbar />
    <div class="px-4 md:px-6 pb-6 mt-4">
      <p class="font-semibold text-3xl">Experience Catalog</p>
      <p class="font-semibold text-lg">
        Tap on the Experiences below to preview
      </p>
      <div class="mt-4">
        <div>
          <div class="md:flex md:flex-wrap gap-4 md:gap-6">
            <div
              class="bg-white p-2 border border-gray-200 rounded-xl md:w-1/5 h-full"
            >
              <p class="text-blue-500 font-semibold text-xl">Video</p>
              <div class="h-52 w-full rounded-xl overflow-hidden">
                <img
                  :src="
                    require('@/assets/images/sample-catalog/obk-welcome-video.jpg')
                  "
                  class="w-full h-auto"
                />
              </div>
              <p class="text-xs md:text-sm font-semibold py-2 md:h-40">
                Sit back, relax and let us welcome you. Congratulate yourself on
                beginning your transition from candidate to an employee. This is
                a checkpoint marking your exciting journey towards a successful
                future.
              </p>
              <div
                @click.prevent="gotoExp(obkSlugs.welcomeVideo)"
                class="cursor-pointer preview-button text-white font-semibold text-center uppercase text-lg py-1.5 rounded-lg"
              >
                preview
              </div>
            </div>
            <div
              class="mt-4 md:mt-0 bg-white p-2 border border-gray-200 rounded-xl md:w-1/5 h-full"
            >
              <div>
                <p class="text-yellow-500 font-semibold text-xl">Video</p>
                <div class="h-52 w-full rounded-xl overflow-hidden">
                  <img
                    :src="
                      require('@/assets/images/sample-catalog/obk-induction-video.jpg')
                    "
                    class="w-full h-auto"
                  />
                </div>
                <p class="text-xs md:text-sm font-semibold py-2 md:h-40">
                  Still got questions about us? Catch us in this video and get
                  to know everything there is to know about your company.
                </p>
              </div>
              <div
                @click.prevent="gotoExp(obkSlugs.inductionVideo)"
                class="cursor-pointer preview-button text-white font-semibold text-center uppercase text-lg py-1.5 rounded-lg"
              >
                preview
              </div>
            </div>
            <div
              class="bg-white p-2 border border-gray-200 rounded-xl md:w-1/5 mt-4 md:mt-0 h-full"
            >
              <p class="text-pink-500 font-semibold text-xl">Poll</p>
              <div class="h-52 w-full rounded-xl overflow-hidden">
                <img
                  :src="require('@/assets/images/sample-catalog/obk-ttal.jpg')"
                  class="w-full h-auto"
                />
              </div>
              <p class="text-xs md:text-sm font-semibold py-2 md:h-40">
                The name says it all! Three statements. Two true, one false.
                Were those really tears of happiness when he went bungee jumping
                in Macau? Does she really own five dogs! Get in on all the fresh
                company goss with this game.
              </p>
              <div
                @click.prevent="gotoExp(obkSlugs.ttal)"
                class="cursor-pointer preview-button text-white font-semibold text-center uppercase text-lg py-1.5 rounded-lg"
              >
                preview
              </div>
            </div>
            <div
              class="bg-white p-2 border border-gray-200 rounded-xl md:w-1/5 mt-4 md:mt-0"
            >
              <p class="text-pink-500 font-semibold text-xl">Interactive</p>
              <div class="h-52 w-full rounded-xl overflow-hidden">
                <img
                  :src="
                    require('@/assets/images/sample-catalog/obk-culture-framework.jpg')
                  "
                  class="w-full h-auto"
                />
              </div>
              <p class="text-xs md:text-sm font-semibold py-2 md:h-40">
                Introduce your candidates and employees to your organisation
                culture. While we understand that culture needs to be
                experienced, as a first step use our interactive 3d feature to
                get them to know what you stand for.
              </p>
              <div
                class="cursor-pointer coming-soon-button text-gray-500 font-semibold text-center uppercase text-lg py-1.5 rounded-lg"
              >
                Coming Soon
              </div>
            </div>
            <div
              class="bg-white p-2 border border-gray-200 rounded-xl md:w-1/5 mt-4 md:mt-0"
            >
              <p class="text-pink-500 font-semibold text-xl">Interactive</p>
              <div class="h-52 w-full rounded-xl overflow-hidden">
                <img
                  :src="
                    require('@/assets/images/sample-catalog/obk-leadership.jpg')
                  "
                  class="w-full h-auto"
                />
              </div>
              <p class="text-xs md:text-sm font-semibold py-2 md:h-40">
                Connect your candidates to your leaders. Build trust. Get them
                to communicate with your leaders and connect with your
                organisation.
              </p>
              <div
                class="cursor-pointer coming-soon-button text-gray-500 font-semibold text-center uppercase text-lg py-1.5 rounded-lg"
              >
                Coming Soon
              </div>
            </div>
            <div
              class="bg-white p-2 border border-gray-200 rounded-xl md:w-1/5 mt-4 md:mt-0"
            >
              <p class="text-pink-500 font-semibold text-xl">Survey</p>
              <div class="h-52 w-full rounded-xl overflow-hidden">
                <img
                  :src="
                    require('@/assets/images/sample-catalog/obk-candidate-exp-survey.jpg')
                  "
                  class="w-full h-auto"
                />
              </div>
              <p class="text-xs md:text-sm font-semibold py-2 md:h-40">
                Find out how your candidates felt about the recruitment process
                and their journey so far.
              </p>
              <div
                class="cursor-pointer coming-soon-button text-gray-500 font-semibold text-center uppercase text-lg py-1.5 rounded-lg"
              >
                Coming Soon
              </div>
            </div>
            <div
              class="bg-white p-2 border border-gray-200 rounded-xl md:w-1/5 mt-4 md:mt-0"
            >
              <p class="text-red-500 font-semibold text-xl">Game</p>
              <div class="h-52 w-full rounded-xl overflow-hidden">
                <img
                  :src="require('@/assets/images/sample-catalog/obk-gtm.jpg')"
                  class="w-full h-auto"
                />
              </div>
              <p class="text-xs md:text-sm font-semibold py-2 md:h-40">
                As straightforward as it can get. Rack your collective brains
                and glean into the pop culture/movie referenced by the sequence
                of empjis? Guess The Movie is a refreshing alternative from the
                plethora of logo quiz games in use out there!
              </p>
              <div
                @click.prevent="gotoExp(obkSlugs.gtm)"
                class="cursor-pointer preview-button text-white font-semibold text-center uppercase text-lg py-1.5 rounded-lg"
              >
                preview
              </div>
            </div>
            <div
              class="bg-white p-2 border border-gray-200 rounded-xl md:w-1/5 mt-4 md:mt-0"
            >
              <p class="text-blue-500 font-semibold text-xl">Immersive</p>
              <div class="h-52 w-full rounded-xl overflow-hidden">
                <img
                  :src="
                    require('@/assets/images/sample-catalog/obk-welcome-360.jpg')
                  "
                  class="w-full h-auto"
                />
              </div>
              <p class="text-xs md:text-sm font-semibold py-2 md:h-40">
                It's your first day and a special milestone. Use our Augmented
                Reality feature to make it even more special and memorable.
                Lighten up your mood for a memorable first day.
              </p>
              <div
                @click.prevent="gotoExp(obkSlugs.welcome360)"
                class="cursor-pointer preview-button text-white font-semibold text-center uppercase text-lg py-1.5 rounded-lg"
              >
                preview
              </div>
            </div>
            <div
              class="bg-white p-2 border border-gray-200 rounded-xl md:w-1/5 mt-4 md:mt-0"
            >
              <p class="text-green-700 font-semibold text-xl">Survey</p>
              <div class="h-52 w-full rounded-xl overflow-hidden">
                <img
                  :src="
                    require('@/assets/images/sample-catalog/obk-pulse-check-1-week.jpg')
                  "
                  class="w-full h-auto"
                />
              </div>
              <p class="text-xs md:text-sm font-semibold py-2 md:h-40">
                Participate in regular pulse surveys to give a quick feedback to
                the company as needed. Use this service to bring forth any
                real-time issues you might be facing, to improve your experience
                with us.
              </p>
              <div
                @click.prevent="gotoExp(obkSlugs.pulseOneWeek)"
                class="cursor-pointer preview-button text-white font-semibold text-center uppercase text-lg py-1.5 rounded-lg"
              >
                preview
              </div>
            </div>
            <div
              class="bg-white p-2 border border-gray-200 rounded-xl md:w-1/5 mt-4 md:mt-0"
            >
              <p class="text-pink-500 font-semibold text-xl">Interactive</p>
              <div class="h-52 w-full rounded-xl overflow-hidden">
                <img
                  :src="require('@/assets/images/sample-catalog/obk-evp.jpg')"
                  class="w-full h-auto"
                />
              </div>
              <p class="text-xs md:text-sm font-semibold py-2 md:h-40">
                And *drumroll* here is everything we stand for. As part of this
                family, our values are what make everyone feel special. Explore
                the web below to know what you get, the freedom and perks we
                offer, and to understand what sets this company apart from the
                crowd.
              </p>
              <div
                @click.prevent="gotoExp(obkSlugs.evp)"
                class="cursor-pointer preview-button text-white font-semibold text-center uppercase text-lg py-1.5 rounded-lg"
              >
                preview
              </div>
            </div>
            <div
              class="bg-white p-2 border border-gray-200 rounded-xl md:w-1/5 mt-4 md:mt-0"
            >
              <p class="text-red-500 font-semibold text-xl">Interactive</p>
              <div class="h-52 w-full rounded-xl overflow-hidden">
                <img
                  :src="
                    require('@/assets/images/sample-catalog/obk-new-hire-announcement.jpg')
                  "
                  class="w-full h-auto"
                />
              </div>
              <p class="text-xs md:text-sm font-semibold py-2 md:h-40">
                Feel like you're walking around with a “Probatio” tablet around
                your neck? Hate having to introduce yourself as the 'Newbie'
                every time? Fret not! You are just four simple steps away from
                introducing yourself with a single click.
              </p>
              <div
                @click.prevent="gotoExp(obkSlugs.nha)"
                class="cursor-pointer preview-button text-white font-semibold text-center uppercase text-lg py-1.5 rounded-lg"
              >
                preview
              </div>
            </div>
            <div
              class="bg-white p-2 border border-gray-200 rounded-xl md:w-1/5 mt-4 md:mt-0"
            >
              <p class="text-pink-500 font-semibold text-xl">Interactive</p>
              <div class="h-52 w-full rounded-xl overflow-hidden">
                <img
                  :src="
                    require('@/assets/images/sample-catalog/obk-document-library.jpg')
                  "
                  class="w-full h-auto"
                />
              </div>
              <p class="text-xs md:text-sm font-semibold py-2 md:h-40">
                Yeah yeah, scrolls belong to the ancient civilizations, and it's
                where they should remain. Scrolling through unending pages of
                company documents is a thing of the past. In this virtual world,
                soak in the company's information documents by flipping through
                virtual pages!
              </p>
              <div
                @click.prevent="gotoExp(obkSlugs.documentLibrary)"
                class="cursor-pointer preview-button text-white font-semibold text-center uppercase text-lg py-1.5 rounded-lg"
              >
                preview
              </div>
            </div>
            <div
              class="bg-white p-2 border border-gray-200 rounded-xl md:w-1/5 mt-4 md:mt-0"
            >
              <p class="text-blue-500 font-semibold text-xl">Tool</p>
              <div class="h-52 w-full rounded-xl overflow-hidden">
                <img
                  :src="
                    require('@/assets/images/sample-catalog/scratch-card.svg')
                  "
                  class="w-full h-auto"
                />
              </div>
              <p class="text-xs md:text-sm font-semibold py-2 md:h-40">
                Use this Scratch Card Tool to communicate something fun or
                exciting.
              </p>
              <div
                @click.prevent="gotoExp(obkSlugs.demoScratchCard)"
                class="cursor-pointer preview-button text-white font-semibold text-center uppercase text-lg py-1.5 rounded-lg"
              >
                preview
              </div>
            </div>
            <div
              class="bg-white p-2 border border-gray-200 rounded-xl md:w-1/5 mt-4 md:mt-0"
            >
              <p class="text-red-500 font-semibold text-xl">Tool</p>
              <div class="h-52 w-full rounded-xl overflow-hidden">
                <img
                  :src="
                    require('@/assets/images/sample-catalog/spin-the-wheel.svg')
                  "
                  class="w-full h-auto"
                />
              </div>
              <p class="text-xs md:text-sm font-semibold py-2 md:h-40">
                Make your communication engaging and fun. Even a reject message
                can be delivered to bring about a smile or to remember the
                effort you have put in to communicate.
              </p>
              <div
                @click.prevent="gotoExp(obkSlugs.communication.spinthewheel)"
                class="cursor-pointer preview-button text-white font-semibold text-center uppercase text-lg py-1.5 rounded-lg"
              >
                preview
              </div>
            </div>
            <div
              class="bg-white p-2 border border-gray-200 rounded-xl md:w-1/5 mt-4 md:mt-0"
            >
              <p class="text-yellow-500 font-semibold text-xl">Tool</p>
              <div class="h-52 w-full rounded-xl overflow-hidden">
                <img
                  :src="require('@/assets/images/sample-catalog/jigsaw.svg')"
                  class="w-full h-auto"
                />
              </div>
              <p class="text-xs md:text-sm font-semibold py-2 md:h-52">
                Jigsaw Puzzles are an age-old favorite for people of all ages.
                Get together with your colleagues to work out a brain-teasing
                puzzle. Piece together, part by part, the company's grand
                message. It could be a new feature of your product, a new
                service, a new team member's photo, or even an invite to the
                next gala event. Whatever it is, you'll know soon!
              </p>
              <div
                @click.prevent="gotoExp(obkSlugs.demoJigsaw)"
                class="cursor-pointer preview-button text-white font-semibold text-center uppercase text-lg py-1.5 rounded-lg"
              >
                preview
              </div>
            </div>
            <div
              class="bg-white p-2 border border-gray-200 rounded-xl md:w-1/5 mt-4 md:mt-0"
            >
              <p class="text-pink-500 font-semibold text-xl">Tool</p>
              <div class="h-52 w-full rounded-xl overflow-hidden">
                <img
                  :src="
                    require('@/assets/images/sample-catalog/celebrations.svg')
                  "
                  class="w-full h-auto"
                />
              </div>
              <p class="text-xs md:text-sm font-semibold py-2 md:h-52">
                People who celebrate with you are the best. So are we ;) Enjoy
                unique featured virtual experiences on every birthday,
                achievement, and special occasion!
              </p>
              <div
                @click.prevent="gotoExp(obkSlugs.demoBirthday)"
                class="cursor-pointer preview-button text-white font-semibold text-center uppercase text-lg py-1.5 rounded-lg"
              >
                preview
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Demo/Umwelt/DesktopNavbar.vue";
import Footer from "@/components/Demo/CatalogElements/DesktopFooter.vue";
import obkSlugs from "@/common/slugs";

export default {
  name: "SampleExpsCatalog",
  components: { Navbar, Footer },
  data() {
    return {
      obkSlugs,
    };
  },
  created() {},
  methods: {
    gotoExp(slug) {
      this.$router.push({
        name: this.obkSlugs.expSamplePaths[slug],
      });
    },
  },
  computed: {},
};
</script>

<style scoped>
.preview-button {
  background: #3d50e0;
}

.coming-soon-button {
  background: #cccccc;
}
</style>
